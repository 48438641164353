import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// reactstrap components
import {
  // Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";

import { Api } from "api";
import { /*setCurrentUser, */ logoutSuccess } from "actions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
// import * as actions from '../../modules/Demande/actions';
// import ModalConseil from "../ModalConseil";
function TopNavbar(props) {
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  const { isConnected, history } = props;

  useEffect(() => {
    setConnected(isConnected);
  }, [isConnected]);
  // const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  const logout = async () => {
    try {
      await Api().get("/auth/users/signout");
      dispatch(logoutSuccess());
      history.replace("/auth/signin");
      // actionsDispatch.clearCreationForm();
    } catch (error) {
      dispatch(logoutSuccess());
      history.replace("/auth/signin");
    }
  };

  const GoAccueil = () => {
    history.replace("/step/requestAuthorization");
    // actionsDispatch.clearCreationForm();
  };

  const [modalConseil, setModalConseil] = useState(false);

  const toggleModalConseil = () => setModalConseil(!modalConseil);

  return (
    <div className="landing-navbar">
      <div className="topbar-right2 btnMenu">
        {!props.logoOnly && connected ? (
          <UncontrolledDropdown className="button-dropdown button-shaddow">
            <DropdownToggle
              caret
              data-toggle="dropdown"
              href="#pablo"
              id="navbarDropdown"
              tag="a"
              onClick={(e) => e.preventDefault()}
            >
              {/* <img
                alt="logo"
                src={require('../../assets/img/iconMenu.png')}
                className="button-bar"
                onClick={() => {
                  history.push('/landing');
                }}
                tag="button"
              /> */}
              <span className="button-bar"></span>
              <span className="button-bar"></span>
              <span className="button-bar"></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbarDropdown">
              {/* <DropdownItem to="/" tag={Link}>
                <span className="home-navlink text-semi"> Accueil</span>
              </DropdownItem>
              <DropdownItem to="/requests" tag={Link}>
                <span className="home-navlink text-semi"> Mes demandes</span>
              </DropdownItem>
              <DropdownItem to="/sinistres" tag={Link}>
                <span className="home-navlink text-semi"> Mes sinistres</span>
              </DropdownItem> */}
              <DropdownItem
                onClick={() => {
                  GoAccueil();
                }}
              >
                <span className="home-navlink text-semi">Accueil</span>
              </DropdownItem>
              <DropdownItem onClick={() => logout()}>
                <span className="home-navlink text-semi">Se déconnecter</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <div />
        )}
      </div>
      {/* <img
        alt="logo"
        src={require('../../assets/img/logo.png')}
        className="app-logo"
        onClick={() => {
          history.push('/landing');
        }}
        tag="button"
      /> */}
      {/* <ModalConseil modal={modalConseil} toggle={toggleModalConseil} /> */}
      <div className="app-logo">logo</div>
      {!props.logoOnly ? (
        <div className="topbar-right2 ">
          {connected ? (
            <div className="topbar-menu">
              <Button
                onClick={() => setModalConseil(true)}
                className="nav-right-item btnConseil"
              >
                Un Conseil ?
              </Button>
              <Button
                onClick={() => {
                  // actionsDispatch.clearCreationForm();
                  props.history.push("/requests/list");
                }}
                className="nav-right-item btnBord"
              >
                Tableau de bord
              </Button>
            </div>
          ) : (
            <>
              <div
                onClick={() => {
                  history.push("/auth/signin");
                }}
                className="nav-right-item"
              >
                Se connecter
              </div>
              {/*<div
                onClick={() => {
                  history.push('/auth/signup');
                }}
                className="nav-right-item">
                S'inscrire
              </div>*/}
            </>
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default withRouter(TopNavbar);
