import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, Row, Col, Spinner } from "reactstrap";
import Select from "react-select";
import "../../../Expeditions.styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import AppInput from "components/AppInput";
import { useUpdateStatuts } from "modules/Expeditions/hooks/useUpdateStatus";
import DateTime from "components/DateTime";
import CustomSimpleSelect from "components/CustomSimpleSelect";
const ModalConfirmation = ({ services, isOpen, onCloseModal, id }) => {
  const [state, setState] = useState({});
  useEffect(() => {
    setState({ ...state, services: services });
  }, [services]);
  //console.log("services", state);
  const [{ fetching, error }, UpduseUpdateStatuts] = useUpdateStatuts();
  const onCreate = () => {
    const payload = {
      comment: state.comment,
      date: state.date,
    };
    if (state.status?.value === "loaded") {
      UpduseUpdateStatuts(id, "load", payload);
    } else if (state.status?.value === "shipped") {
      UpduseUpdateStatuts(id, "ship", payload);
    } else if (state.status?.value === "excepted") {
      UpduseUpdateStatuts(id, "except", payload);
    } else if (state.status?.value === "processing") {
      UpduseUpdateStatuts(id, "process", payload);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        onCloseModal();
      }}
      className="ModalConfirmation"
    >
      <ModalBody>
        <p style={{ fontSize: "26px" }} className="text-bold mb-0">
          Confirmation
        </p>
        {/* <p style={{ fontSize: "20px" }} className="text-medium mb-0">
          Etes-vous sûr de vouloir changer le statut{" "}
          <span className="text-bold">{status?.label}</span>
        </p> */}
        <Row>
          <Col xs="11">
            <CustomSimpleSelect
              label={"Statut :"}
              placeholder="Choisir"
              options={[
                { label: "Nouvelle", value: "new" },
                { label: "En cours de traitement", value: "processing" },
                {
                  label: "En Cours de livraison",
                  value: "loaded",
                },
                { label: "Livrée", value: "shipped" },
                { label: "Exception", value: "excepted" },
              ]}
              value={state.status}
              isClearable={false}
              onSelectValue={(v) => {
                setState({ ...state, status: v });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="11">
            <AppInput
              label="Commentaire :"
              placeholder={"Vous pouvez rajouter un Commentaire ici .."}
              onChange={(t) => setState({ ...state, comment: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="11">
            <DateTime
              label="Date et heure :"
              value={state.date}
              onChange={(date) =>
                setState({
                  ...state,
                  date: date,
                })
              }
              minDate={false}
            />
          </Col>
        </Row>
      </ModalBody>
      <div
        style={{ justifyContent: "space-around" }}
        className="modal-footer mt-4"
      >
        <span
          onClick={(e) => {
            onCloseModal();
            setState({ services: services, comment: "" });
          }}
          className="text-bold"
          style={{
            color: "#0095c9",
            fontSize: "18px",
            marginLeft: " 40px",
            cursor: "pointer",
          }}
        >
          Retourner
        </span>
        <Button
          className="text-bold btnConfirmation"
          onClick={() => {
            onCreate();
          }}
          disabled={!state.status}
        >
          {fetching ? <Spinner size="sm" /> : <span> Confirmer </span>}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
