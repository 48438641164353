export function sendInfoUrl(id) {
  return `/procedures/${id}/vente-en-ligne/2`;
}

export function devisById(id) {
  return `/requests/${id}`;
}

export function dashbordUrl(role, params) {
  return `/admin/courses/dashboard?${params}`;
}
