import {
  SET_DETAILS_EXPEDITIONS,
  SET_CURRENT_EXPEDITIONS,
} from "./actionsTypes";

export const setCurrentExpeditions = (payload) => ({
  type: SET_CURRENT_EXPEDITIONS,
  payload,
});
export const setDetailsExpeditions = (payload) => ({
  type: SET_DETAILS_EXPEDITIONS,
  payload,
});
