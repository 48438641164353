import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { expeditionsByIdUrl } from "../api/endpoints";

export function useUpdateExpeditions(company) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function updateExpeditions(role, payload, uploadPayload) {
    try {
      setFetching(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      await Api().put(expeditionsByIdUrl(role, company), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      dispatch(push("/expeditions/list"));
    } catch (error) {
      reportError("updateExpeditions error", error);
      setError(error);
      setFetching(false);
    } finally {
      //   setFetching(false);
    }
  }

  return [{ fetching, error }, updateExpeditions];
}
