import React from "react";
import { withRouter } from "react-router";

// reactstrap components

import "assets/css/topbar.css";

function AuthNavbar(props) {
  return (
    <div className="landing-navbar">
      <div className="topbar-right  btnMenu ">
        {!props.signin && (
          <img
            alt="logo"
            src={require("assets/img/arrow_left_circle.png")}
            className="top-bar-icon"
            onClick={() => {
              props.history.replace("/auth/signin");
            }}
            tag="button"
          />
        )}
      </div>
      <div className="app-logo">
        <img
          alt="logo"
          src={require("assets/img/logo.png")}
          className="top-bar-icon"
          onClick={() => {
            props.history.replace("/auth/signin");
          }}
        />
      </div>
      <div className="topbar-right"></div>
    </div>
  );
}

export default withRouter(AuthNavbar);
