import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const brdMap = [
  ,// {
//   path: "/settings/themes",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem>
//         <a className={`app-text-${theme}`} href="#/settings/products/list">
//           Paramètres
//         </a>
//       </BreadcrumbItem>
//       <BreadcrumbItem active>Thème</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// ,
// {
//   path: "/utilisateurs/list",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem active>Utilisateurs</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// {
//   path: "/utilisateurs/details",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem>
//         <a className={`app-text-${theme}`} href="#/utilisateurs/list">
//           Utilisateurs
//         </a>
//       </BreadcrumbItem>
//       <BreadcrumbItem active>Détail Utilisateurs</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// {
//   path: "/utilisateurs/create",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem>
//         <a className={`app-text-${theme}`} href="#/utilisateurs/list">
//           Utilisateurs
//         </a>
//       </BreadcrumbItem>
//       <BreadcrumbItem active>Nouvelle Utilisateurs</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// ,
// {
//   path: "/hotels/list",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem active>Hôtels</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// {
//   path: "/hotels/details",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem>
//         <a className={`app-text-${theme}`} href="#/hotels/list">
//           Hôtels
//         </a>
//       </BreadcrumbItem>
//       <BreadcrumbItem active>Détail Hôtel</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
// {
//   path: "/hotels/create",
//   display: true,
//   component: ({ theme }) => (
//     <Breadcrumb>
//       <BreadcrumbItem>
//         <a className={`app-text-${theme}`} href="#/hotels/list">
//           Hôtels
//         </a>
//       </BreadcrumbItem>
//       <BreadcrumbItem active>Nouvel Hôtel</BreadcrumbItem>
//     </Breadcrumb>
//   ),
// },
////////////////
];

export default brdMap;
