import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const listStateSelect = createSelector(
  (state) => state.expeditions,
  (expeditions) => expeditions?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.expeditions,
  (expeditions) => expeditions.details
);

export const createStateSelect = createSelector(
  (state) => state.expeditions,
  (expeditions) => expeditions?.create
);
