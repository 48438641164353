import React from "react";
import "../../styles.scss";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
import moment from "moment";

// const { roles } = useSelector(currentUserStateSelect) || {};
export const columns = [
  {
    title: "Nom complet",
    dataIndex: "name",
    renderItem: (info, props) => (
      <span className="text-center">{info.name}</span>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    renderItem: (info, props) => (
      <span className="text-center">{info?.email}</span>
    ),
  },

  {
    title: "",
    dataIndex: "none",
    renderItem: () => {
      return (
        <div className="text-right">
          <img
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
