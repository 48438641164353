import React, { useState, useMemo, useEffect } from "react";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useUpdate } from "../../hooks/useUpdate";
import useFetchElement from "hooks/useFetchElement";
import { userByIdUrl } from "../../api/endpoints";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import Switch from "../../../../components/Switch";
import ModalConfirmer from "./ModalConfirmation/ModalConfirmation";
import moment from "moment";

const Details = (props) => {
  const current = props.details;
  const { data /*, fetching */ } = useFetchElement(
    current,
    userByIdUrl(props.userRole, current?._id)
  );
  const [{ fetching, error }, update] = useUpdate(current?._id);

  const user = useMemo(() => data || current, [current, data]);
  const [state, setState] = useState({});

  console.log(state);
  const onUpdateData = () => {
    // update(props.userRole, payload);
  };

  console.log("");

  const [show, setShow] = useState(false);

  // const infoCommande = useSelector(commandeStateSelect);

  return (
    <div className="app-main details">
      <PageDescription title="Détails" />
    </div>
  );
};

export default Details;
