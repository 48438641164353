import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { userByIdUrl } from "../api/endpoints";

export function useUpdate(company) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function update(role, payload, uploadPayload) {
    try {
      setFetching(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      await Api().put(userByIdUrl(role, company), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      dispatch(push("/utilisateurs/list"));
    } catch (error) {
      reportError("update error", error);
      setError(error);
      setFetching(false);
    } finally {
      //   setFetching(false);
    }
  }

  return [{ fetching, error }, update];
}
