import React, { useState, useEffect } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useCreate } from "../../hooks/useCreate";
import Switch from "../../../../components/Switch";
import { Flag, SettingsEthernet } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
import axios from "axios";
const Create = (props) => {
  const [state, setState] = useState({
    $q: "",
  });
  const [{ fetching, error }, create] = useCreate();
  const { roles } = useSelector(currentUserStateSelect) || {};

  console.log("role ===>>>", roles);
  const [isCode, setIsCode] = useState(false);
  const invalidForm = !state.fullName || !state.email;

  const onCreate = () => {
    const payload = {
      name: state.fullName,
      email: state.email,
      // phone: state.phone,
    };
    create(roles[0], payload);
  };

  const dispatch = useDispatch();

  console.log(state);
  return (
    <div className="app-main">
      <PageDescription title="Nouvelle Utilisateur" />
      <div className="creation-form">
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Nom Complet"
              required
              type="text"
              value={state.fullName}
              onChange={(t) => setState({ ...state, fullName: t })}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Téléphone"
              type="number"
              value={state.phone}
              onChange={(t) => setState({ ...state, phone: t })}
            />
          </Col>
        </Row> */}
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Email"
              type="email"
              required
              value={state.email}
              onChange={(t) => setState({ ...state, email: t })}
            />
          </Col>
        </Row>
      </div>
      {error && <h6 className="auth-error">{error?.message}</h6>}

      <Button
        className={` space-left btnNext ${props.theme}`}
        onClick={(e) => {
          e.preventDefault();
          onCreate();
        }}
        size="lg"
        disabled={invalidForm}
      >
        {fetching ? (
          <Spinner size="sm" />
        ) : (
          <span className="text-bold"> Créer Utilisateur</span>
        )}
      </Button>
    </div>
  );
};

export default Create;
