import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageDescription from "components/PageDescription";
import { Row, Col, Label } from "reactstrap";
import { dashbordUrl } from "./api/endpoints";
import moment from "moment";
import useFetchList from "hooks/useFetchList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { useSelector, useDispatch } from "react-redux";
import { listStateSelect, currentUserStateSelect } from "./selector";
import "./dashboard-styles.scss";
function HealthDocs({ match, location }) {
  const { roles } = useSelector(currentUserStateSelect) || {};
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10, $q: "" });
  const [apiEndpoint, setEndpoint] = useState(dashbordUrl(roles?.[0]));
  const { data, count, fetching } = useFetchList(apiEndpoint);
  const [dateRange, setDateRange] = useState([]);
  const [created_at_from, created_at_to] = dateRange;

  useEffect(() => {
    const urlQuery = created_at_from
      ? `${`&created_at_from=${moment(created_at_from).format(
          "DD/MM/YYYY"
        )}&created_at_to=${moment(created_at_to).format(
          "DD/MM/YYYY"
        )}`}&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }`
      : `&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }`;
    setEndpoint(dashbordUrl(roles?.[0], urlQuery));
  }, [created_at_from, created_at_to, dateRange]);

  console.log(data);

  return (
    <div className="app-main">
      <Helmet>
        <title>TMM</title>
        <meta name="Tableau de bord" content="Tableau de bord" />
      </Helmet>
      <PageDescription title="Tableau de bord" />
      <Row className="mt-4 ml-2">
        <Col>
          <div>
            {/* <Label>Période</Label>
          <br /> */}
            <DatePicker
              placeholderText="Choisir date"
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              startDate={created_at_from}
              endDate={created_at_to}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              locale={fr}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-4 ml-2">
        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">
              {data?.status?.find((item) => item.status === "new")?.count}
            </div>
            <div>Nombre des expéditions Nouvelle&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>

        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">
              {
                data?.status?.find((item) => item.status === "processing")
                  ?.count
              }
            </div>
            <div>Nombre des expéditions en cours de traitement&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>

        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">
              {data?.status?.find((item) => item.status === "loaded")?.count}
            </div>
            <div>Nombre des expéditions en cours de livraison&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>

        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">
              {data?.status?.find((item) => item.status === "shipped")?.count}
            </div>
            <div>Nombre des expéditions livrés&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>

        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">
              {data?.status?.find((item) => item.status === "excepted")?.count}
            </div>
            <div>Nombre des expéditions exceptions&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>

        <div className="cardBord">
          <div className="valeurBord">
            <div className="text-bold">{data.total}</div>
            <div>Nombre total des expéditions&nbsp;</div>{" "}
          </div>
          <div className="imageBord">
            <img width="100%" src={require("assets/img/iconFile.png")} />
          </div>
        </div>
      </Row>
      {/* <Row className="mt-4 ml-2">
        <span>Nombre des expéditions Nouvelle :&nbsp;</span>{" "}
        <span className="text-bold">
          {data?.status?.find((item) => item.status === "new")?.count}
        </span>
      </Row>
      <Row className="mt-2 ml-2">
        <span>Nombre des expéditions en cours de traitement :&nbsp;</span>{" "}
        <span className="text-bold">
          {data?.status?.find((item) => item.status === "processing")?.count}
        </span>
      </Row>
      <Row className="mt-2 ml-2">
        <span>Nombre des expéditions en cours de livraison :&nbsp;</span>{" "}
        <span className="text-bold">
          {data?.status?.find((item) => item.status === "loaded")?.count}
        </span>
      </Row>
      <Row className="mt-2 ml-2">
        <span>Nombre des expéditions livrés :&nbsp;</span>{" "}
        <span className="text-bold">
          {data?.status?.find((item) => item.status === "shipped")?.count}
        </span>
      </Row>
      <Row className="mt-2 ml-2">
        <span>Nombre des expéditions exceptions :&nbsp;</span>{" "}
        <span className="text-bold">
          {data?.status?.find((item) => item.status === "excepted")?.count}
        </span>
      </Row>
      <Row className="mt-2 ml-2">
        <span>Nombre total des expéditions :&nbsp;</span>{" "}
        <span className="text-bold">{data.total}</span>
      </Row> */}
    </div>
  );
}

export default HealthDocs;
