// Api
import { Api } from "api";

import reportError from "lib/errorHandler";

// some endpoints
import { meUrl, uploadFileUrl } from "api/endpoints";

import {
  GET_CURRENT_USER_R,
  GET_CURRENT_USER_F,
  SET_CURRENT_USER,
  LOGOUT_S,
  SET_SIDEBAR_OPEN,
  SET_ACTIVE_TAB,
} from "actionsTypes";

export const setSidebarOpen = (payload) => ({
  type: SET_SIDEBAR_OPEN,
  payload,
});

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export const logoutSuccess = () => ({
  type: LOGOUT_S,
});

export const getCurrentUserRequest = () => ({
  type: GET_CURRENT_USER_R,
});

export const getCurrentUserFailed = (payload) => ({
  type: GET_CURRENT_USER_F,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const getMe = () => {
  return (dispatch) => {
    dispatch(getCurrentUserRequest());
    Api()
      .get(meUrl())
      .then((data) => {
        if (data) {
          dispatch(setCurrentUser(data));
        } else {
        }
      })
      .catch((err) => {
        dispatch(getCurrentUserFailed());
        reportError("get me error", err);
      });
  };
};

export const uploadFiles = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadFileUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};
