export function expeditionsUrl(role, params) {
  return params && `/admin/courses/?${params}&$sort={ "updated_at": "DESC"}`;
}

export function createExpeditionsUrl(role) {
  return `/admin/courses/`;
}
export function expeditionsByIdUrl(role, id) {
  return `/admin/courses/${id}`;
}
export function searchByIdUrl(id) {
  return `/courses/code/${id}`;
}
export function updateStatutsUrl(id, type) {
  return `/admin/courses/${id}/${type}`;
}
