import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
// core components
import AuthNavbar from "components/Navbars/AuthNavbar";

import "./forgot-styles.scss";

// some hooks
import { useForgotPass } from "../../hooks/useForgotPass";

function ForgotPass(props) {
  useEffect(() => {
    document.body.classList.add("login-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const [username, setUsername] = useState("");

  const [{ fetching, error }, forgotPassword] = useForgotPass();

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = { username };
    forgotPassword(payload);
  };

  return (
    <div className="app-container forgot-page">
      <AuthNavbar register />
      <div
        style={{ marginTop: "20vh", minHeight: "85vh" }}
        className="bg-image forgot-content"
      >
        <Col md="6">
          <Form onSubmit={onSubmit} action="" className="form" method="">
            <Card className="card-login card-plain">
              <CardBody>
                <h3 className="text-black text-bold mt-4">
                  Demander un nouveau mot de passe
                </h3>
                <FormGroup>
                  <Label className="text-black" for="reg-lastname">
                    Login
                  </Label>
                  <Input
                    id="reg-lastname"
                    name="reg-lastname"
                    placeholder=""
                    type="text"
                    className="custom-input"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.currentTarget.value);
                    }}
                  />
                </FormGroup>
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  block
                  className="btn-round on-secondary"
                  color="secondary"
                  size="lg"
                  type="submit"
                  disabled={!username || fetching}
                >
                  {fetching ? <Spinner size="sm" /> : <span> Envoyer </span>}
                </Button>
              </CardFooter>
              {error && (
                <h6 className="auth-error">
                  {error.message || "Erreur de connexion"}
                </h6>
              )}
            </Card>
          </Form>
        </Col>
      </div>
    </div>
  );
}

export default ForgotPass;
