import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";
import { v4 as uuidv4 } from "uuid";
// endpoints
import { updateStatutsUrl } from "../api/endpoints";
import { createStateSelect } from "../selector";
export function useUpdateStatuts() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const details = useSelector(createStateSelect);
  async function UpdateStatuts(id, type, payload) {
    try {
      // mode: details.mode.lable,
      setFetching(true);
      const rep = await Api().post(updateStatutsUrl(id, type), payload);
      dispatch(push("/expeditions/list"));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetching(false);
    } finally {
      // setFetching(false);
    }
  }

  return [{ fetching, error }, UpdateStatuts];
}
