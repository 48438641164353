import React, { useState, useMemo, useEffect } from "react";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useUpdateExpeditions } from "modules/Expeditions/hooks/useUpdateExpeditions";
import useFetchElement from "hooks/useFetchElement";
import { expeditionsByIdUrl } from "../../api/endpoints";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import Switch from "../../../../components/Switch";
import ModalConfirmer from "./ModalConfirmation/ModalConfirmation";
import moment from "moment";

const DetailsExpeditions = (props) => {
  const current = props.details;
  const { data /*, fetching */ } = useFetchElement(
    current,
    expeditionsByIdUrl(props.userRole, current?._id)
  );
  const [{ fetching, error }, updateExpeditions] = useUpdateExpeditions(
    current?._id
  );

  const Expeditions = useMemo(() => data || current, [current, data]);
  const [state, setState] = useState({
    status:
      Expeditions.status === "new"
        ? { label: "Nouvelle", value: Expeditions.status }
        : Expeditions.status === "loaded"
        ? { label: "En Cours de livraison", value: Expeditions.status }
        : Expeditions.status === "shipped"
        ? { label: "Livrée", value: Expeditions.status }
        : Expeditions.status === "excepted"
        ? { label: "Exception", value: Expeditions.status }
        : Expeditions.status === "processing"
        ? { label: "En cours de traitement", value: Expeditions.status }
        : "",
  });

  console.log(state);
  const onUpdateData = () => {
    // updateExpeditions(props.userRole, payload);
  };

  console.log("Expeditions", Expeditions);

  const [show, setShow] = useState(false);

  const changerStatus = (status) => {
    if (status === "new" && Expeditions.status !== "new") {
      setShow(false);
    } else if (status === "loaded" && Expeditions.status !== "loaded") {
      setShow(true);
    } else if (status === "processing" && Expeditions.status !== "processing") {
      setShow(true);
    } else if (status === "shipped" && Expeditions.status !== "shipped") {
      setShow(true);
    } else if (status === "excepted" && Expeditions.status !== "excepted") {
      setShow(true);
    }
  };

  // const infoCommande = useSelector(commandeStateSelect);

  return (
    <div className="app-main details">
      <ModalConfirmer
        isOpen={show}
        onCloseModal={() => setShow(false)}
        status={state.status}
        id={Expeditions.id}
      />
      <PageDescription title="Détails de l'Expéditions" />
      <Row
        style={{ display: "flex", justifyContent: "flex-end", padding: "20px" }}
      >
        <Col lg="12" sm="12">
          <div className="header">
            <div className="link1 mt-2">
              <a
                style={{ padding: "12px", height: "50px" }}
                className={`space-left text-bold btnNext ${props.theme}`}
                href={`https://api.mytmm.flexi-apps.com/api/v1/admin/courses/${Expeditions.id}/delivery_note`}
                target="_blank"
              >
                <span>AWB</span>
              </a>
            </div>
            <div className=" link2 mt-2">
              <a
                style={{ padding: "12px", height: "50px", marginTop: "11px" }}
                className={`space-left text-bold btnTickit ${props.theme}`}
                href={`https://api.mytmm.flexi-apps.com/api/v1/admin/courses/${Expeditions.id}/sticker`}
                target="_blank"
              >
                <span>Télécharger l'étiquette</span>
              </a>
            </div>
            {/* <div className="ml-4">
              <CustomSimpleSelect
                // label={"Mode"}
                placeholder="Status"
                options={[
                  { label: "Nouvelle", value: "new" },
                  { label: "En cours de traitement", value: "processing" },
                  {
                    label: "En Cours de livraison",
                    value: "loaded",
                  },
                  { label: "Livrée", value: "shipped" },
                  { label: "Exception", value: "excepted" },
                ]}
                defaultValue={[state.status]}
                isClearable={true}
                onSelectValue={(v) => {
                  changerStatus(v?.value);
                  setState({ ...state, status: v });
                }}
              />
            </div> */}
            <div className=" link3 mt-2" onClick={() => setShow(true)}>
              <a
                style={{
                  padding: "12px 30px",
                  height: "50px",
                  marginTop: "11px",
                }}
                className={`space-left text-bold btnChangeStatus ${props.theme}`}
              >
                <span>Changer le statut</span>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" sm="12">
          <span className="sousTitle text-bold">Coordonnées</span>
          <div className="creation-form">
            <Row className="mt-2">
              <span className="text-bold"> Date de création :&nbsp; </span>
              <span>
                {" "}
                {`${moment(Expeditions.created_at).format("DD/MM/YYYY ")}`}
              </span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">AWB Ref :&nbsp;</span>
              <span> {Expeditions?.code}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Mode :&nbsp; </span>
              <span> {Expeditions?.mode}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Origine :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.name}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Origine (société) :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.company}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Adresse :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.address1}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Pays :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.country}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Ville :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.city}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Code postal :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.zipcode}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Téléphone :&nbsp;</span>{" "}
              <span> {Expeditions?.sender?.phone}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Destinataire :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.name}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Destinataire (société) :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.company}</span>
            </Row>
            {Expeditions?.receiver?.email && (
              <Row className="mt-2">
                <span className="text-bold"> Email :&nbsp; </span>
                <span> {Expeditions?.receiver?.email}</span>
              </Row>
            )}
            <Row className="mt-2">
              <span className="text-bold">Adresse :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.address1}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Pays :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.country}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Ville :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.city}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Code postal :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.zipcode}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Téléphone :&nbsp;</span>{" "}
              <span> {Expeditions?.receiver?.phone}</span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Valeur :&nbsp;</span>{" "}
              <span>
                {Expeditions?.value} {Expeditions.currency}
              </span>
            </Row>
            <Row className="mt-2">
              <span className="text-bold">Descriptif :&nbsp;</span>{" "}
              <span> {Expeditions?.description}</span>
            </Row>
          </div>
          {error && <h6 className="auth-error">{error?.result?.message}</h6>}
        </Col>
        <Col lg="6" sm="12">
          <span className="sousTitle text-bold">Conditionnement</span>
          <table className="mt-4 mb-4">
            <tr>
              <th>Nature</th>
              <th>Largeur (cm)</th>
              <th>Hauteur (cm)</th>
              <th>Longueur (cm)</th>
              <th>Poids volumétrique (Kg)</th>
              <th>Quantité</th>
            </tr>
            {Expeditions.load.map((item) => (
              <tr>
                <td>{item.type}</td>
                <td>{item.width}</td>
                <td>{item.height}</td>
                <td>{item.depth}</td>
                <td>{item.volumetric_weight}</td>
                {/* <td>{Number.parseFloat(item.volumetric_weight).toFixed(2)}</td> */}
                <td>{item.quantity}</td>
              </tr>
            ))}
          </table>
          <span className="sousTitle text-bold mt-4 mb-4">Historique</span>
          <hr />
          {Expeditions.history &&
            Expeditions.history.map((item) => (
              <div key={item.id}>
                <Row>
                  <Col xs="12" sm="6">
                    <span
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: "16px",
                        color: "#fff",
                        letterSpacing: "-0.16px",
                        fontWeight: "700",
                      }}
                    >
                      {item.status === "new"
                        ? "Nouvelle"
                        : item.status === "loaded"
                        ? "En Cours de livraison"
                        : item.status === "shipped"
                        ? "Livrée"
                        : item.status === "processing"
                        ? "En cours de traitement"
                        : item.status === "excepted"
                        ? "Exception"
                        : "Sans Status"}
                    </span>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    xs="12"
                    sm="6"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: "14px",
                          color: "#ffff",
                          letterSpacing: "-0.14px",
                        }}
                      >
                        {`${moment(item.date).format("DD/MM/YYYY HH:mm")}`}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2 text-regular" xs="12" sm="6">
                    <span
                      style={{
                        fontStyle: "italic ",
                        fontSize: "16px",
                        color: "#444B54",
                        letterSpacing: "-0.16px",
                      }}
                      className="ml-2 mt-2 text-regular"
                    >
                      {item.comment ? `“${item.comment}”` : ""}
                    </span>
                  </Col>
                </Row>
                <hr />
              </div>
            ))}
          {/* {listComment.length > 9 && (
            <>
              <hr />
              <Row>
                <Col xs="12" sm="12">
                  <span
                    onClick={() => setSkip(skip + 10)}
                    className="text-center mb-4"
                    style={{ cursor: "pointer" }}
                  >
                    Afficher plus
                  </span>
                </Col>
              </Row>
            </>
          )} */}
        </Col>
      </Row>
    </div>
  );
};

export default DetailsExpeditions;
