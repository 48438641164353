import React, { useState, useEffect } from "react";

import { Input, Button } from "reactstrap";
import Select from "react-select";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";
import { FormGroup, Label } from "reactstrap";
import useFetchList from "hooks/useFetchList";

import { userUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import "assets/css/listviews.css";
import RangePicker from "react-range-picker";
import "../../styles.scss";
import downloadApi from "api/downloadApi";
import { downloadFile } from "lib/helpers";
import { Api } from "api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
const List = (props) => {
  const { userRole } = props;
  const [fetchParams, setFecthParams] = useState({ skip: 0, top: 10, $q: "" });
  const [apiEndpoint, setEndpoint] = useState(userUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);
  const [dateRange, setDateRange] = useState([]);
  const [created_at_from, created_at_to] = dateRange;
  console.log("fetchParams", fetchParams);

  useEffect(() => {
    const Query = {
      status: fetchParams.Status?.value,
    };

    const urlQuery = created_at_from
      ? `${`&created_at_from=${moment(created_at_from).format(
          "DD/MM/YYYY"
        )}&created_at_to=${moment(created_at_to).format(
          "DD/MM/YYYY"
        )}`}&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }${"&$filter=" + JSON.stringify(Query)}`
      : `&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }${"&$filter=" + JSON.stringify(Query)}`;
    setEndpoint(userUrl(userRole, urlQuery));
  }, [
    fetchParams.role,
    fetchParams.q,
    fetchParams.skip,
    fetchParams.top,
    userRole,
    created_at_from,
    created_at_to,
    dateRange,
    fetchParams.Status,
  ]);
  const exportCsv = async () => {
    try {
      // data = await Api().get(`/admin/courses/csv`);
      const url = await downloadApi("/admin/courses/csv");
      downloadFile(url, ".csv");
      console.log("data", url);
    } catch (error) {
      console.log("error", error);
      // setError(error);
    }
  };

  console.log("fetchParams", fetchParams);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      backgroundColor: "#f8f8f8",
      borderRadius: "2px",
      height: "50px",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f8f8f8",
      borderRadius: "2px",
      borderWidth: "0px",
      height: "50px",
      width: "100%",
      color: "#373737",
      fontSize: "1.05em",
      fontWeight: "bold",
    }),
  };

  return (
    <div className="app-main list-Utilisateurs">
      <PageDescription title="List Utilisateurs" />

      <div className="filters-view">
        <div></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="mb-2">
            <span
              className="text-bold export-btn"
              tag="button"
              onClick={() => props.history.push("/utilisateurs/create")}
            >
              Créer Utilisateur
            </span>
          </div>
          <div className="ml-2">
            <Input
              width="200px"
              placeholder="Tous"
              type="text"
              name="filter-name"
              className={"filter-input"}
              value={fetchParams.q}
              onChange={(e) => {
                e.preventDefault();
                setFecthParams({
                  q: e.currentTarget.value,
                  skip: 0,
                  top: fetchParams.top,
                });
              }}
            ></Input>
          </div>
        </div>
      </div>

      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune donnée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              // props.actions.setDetails(item);
              // props.history.push("/utilisateurs/details");
            }}
            rowClickable
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams(params);
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams(params);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default List;
