import React from "react";
import { Route, Switch, Redirect } from "react-router";

import { useCheckAuth } from "hooks/useCheckAuth";

import AppLayout from "./components/Layout";

import routes from "./routes";

function App(props) {
  const { isConnected, isReady } = useCheckAuth(props.location.pathname);

  if (isReady) {
    if (!isConnected) {
      return (
        <Redirect
          to={{
            pathname: "/auth",
            state: { from: props.location },
          }}
        />
      );
    }
    return (
      <AppLayout path={props.location.pathname}>
        <Switch>
          {routes.map((route, i) => {
            if (props.location.pathname === "/") {
              return <Redirect key={route.path} to={"/expeditions/list"} />;
            }
            return (
              <Route
                key={i}
                path={route.path}
                render={(props) => <route.component {...props} />}
              />
            );
          })}
        </Switch>
      </AppLayout>
    );
  }
  return <div />;
}

export default App;
