import React, { useState, useMemo, useEffect } from "react";

import { Row, Col, Label, Button } from "reactstrap";

import { THEME_OPTIONS } from "config/app";
import apiConfig from "config/apiConfig";

import PageDescription from "components/PageDescription";
import ColorPicker from "components/ColorPicker";

import { useUpdateLogo } from "./hooks/useUpdateLogo";

const { BASE_URL } = apiConfig;

const ThemingComp = (props) => {
  const [color, setColor] = useState(props.color);

  const selectedColor = useMemo(() => props.color, [props.color]);

  useEffect(
    () => setColor(THEME_OPTIONS.find((one) => one.value === selectedColor)),
    [selectedColor]
  );

  return (
    <div className="app-main">
      <PageDescription title="Modifier le thème" />
      <div className="creation-form">
        <Row>
          <Col xs="12" sm="3">
            <div className="">
              <Label>Couleur 1 </Label>
            </div>
            <ColorPicker selected={color} setSelected={(c) => setColor(c)} />
          </Col>
        </Row>
      </div>
      <div className="creation-footer">
        <Button
          className="btn-round"
          color="default"
          onClick={(e) => {
            e.preventDefault();
            if (props.onPressPrevious) {
              props.onPressPrevious();
            }
            props.history.goBack();
          }}
          size="lg"
        >
          Annuler
        </Button>
        <Button
          className={`btn-round space-left ${props.theme}`}
          onClick={(e) => {
            e.preventDefault();
            props.actions.setThemeColor(color?.value || "primary");
            window.location.reload();
          }}
          size="lg"
          // disabled={nextDisabled || fetching}
        >
          Sauvegarder
        </Button>
      </div>
    </div>
  );
};

export default ThemingComp;
