import React from 'react';
import classNames from 'classnames';
import {FormGroup, Label} from 'reactstrap';
import Datetime from 'react-datetime';

import moment from 'lib/moment';

const DateTimeInput = ({
  label,
  required,
  value,
  onChange,
  minDate,
  hasError,
}) => {
  const today = moment();
  const validDate = function (current) {
    return current.isAfter(today);
  };
  // const validDate2 = function (current) {
  //   return current.isAfter(today);
  // };

  const valid = (current) => {
    return current.isBefore(moment('2004/01/01'));
  };
  return (
    <FormGroup>
      <Label>
        {label}
        {required && <span>*</span>}
      </Label>
      <Datetime
        inputProps={{
          className: classNames('custom-input', {
            // matriculeLast: props.className === 'matricule-last',
            error: value && new Date(value) >= new Date(),
            error: hasError,
          }),
          placeholder: '',
        }}
        // initialValue={moment().subtract(-1, 'days')}
        value={value ? moment(value).format('LLL') : ''}
        closeOnSelect
        onChange={onChange}
        // timeFormat={false}
        isValidDate={minDate ? valid : validDate}
      />
    </FormGroup>
  );
};

export default DateTimeInput;
