import React, { useState } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useCreateExpeditions } from "../../hooks/useCreateExpeditions";
import Switch from "../../../../components/Switch";
import { SettingsEthernet } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { currentUserStateSelect } from "../../selector";
const CreateExpeditions = (props) => {
  const [state, setState] = useState({ $q: "" });
  const [{ fetching, error }, createExpeditions] = useCreateExpeditions();
  const { role } = useSelector(currentUserStateSelect) || {};

  const dispatch = useDispatch();

  // Référence

  const [reference, setReference] = useState([
    {
      _id: uuidv4(),
      type: "",
      width: "",
      height: "",
      weight: "",
      depth: "",
      quantity: "",
    },
  ]);
  const invalidForm = reference.map(
    (item, i) =>
      !reference[i].type ||
      !reference[i].width ||
      !reference[i].height ||
      !reference[i].weight ||
      !reference[i].depth ||
      !reference[i].quantity
  );

  const onCreate = () => {
    createExpeditions(props.userRole, reference);
  };

  console.log("invalidForm", invalidForm);
  const handleChangeInput = (id, event) => {
    const newReference = reference.map((i) => {
      if (id === i._id) {
        if (event.type === "type") {
          i[event.type] = event?.label;
        } else {
          i[event.target.name] = Number.parseFloat(event.target.value);
        }
      }
      return i;
    });
    setReference(newReference);
    setState({ ...state, reference: newReference });
  };

  const handleAddFields = () => {
    setReference([
      ...reference,
      {
        _id: uuidv4(),
        type: "",
        width: "",
        height: "",
        weight: "",
        depth: "",
        quantity: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...reference];
    values.splice(
      values.findIndex((value) => value._id === id),
      1
    );
    setReference(values);
  };

  console.log("reference ====>>", reference);
  return (
    <div className="app-main">
      <PageDescription title="Nouvelle expédition" />
      <span className="sousTitle text-bold">Conditionnement</span>
      <div className="creation-form">
        {reference.map((item) => (
          <>
            <Row>
              <Col xs="12" lg="2" md="3" sm="3">
                <CustomSimpleSelect
                  id={`${item._id}`}
                  label={"Nature"}
                  name="type"
                  required
                  options={[
                    { label: "Document", value: "document", type: "type" },
                    {
                      label: "Marchandise",
                      value: "marchandise",
                      type: "type",
                    },
                  ]}
                  value={[{ label: item.type, value: item.type }]}
                  onSelectValue={(t) => {
                    // console.log(t);
                    handleChangeInput(item._id, t);
                  }}
                />
              </Col>
              <Col xs="12" lg="2" md="3" sm="3">
                <Label className="text-black" for="width">
                  Largeur (cm)
                  <span>*</span>
                </Label>
                <Input
                  className="custom-input"
                  id={`${item._id}`}
                  required
                  name="width"
                  type="number"
                  value={item.width > 0 ? item.width : ""}
                  onChange={(t) => handleChangeInput(item._id, t)}
                />
              </Col>
              <Col xs="12" lg="2" md="3" sm="3">
                <Label className="text-black" for="height">
                  Hauteur (cm)
                  <span>*</span>
                </Label>
                <Input
                  className="custom-input"
                  id={`${item._id}`}
                  required
                  name="height"
                  type="number"
                  value={item.height > 0 ? item.height : ""}
                  onChange={(t) => handleChangeInput(item._id, t)}
                />
              </Col>
              <Col xs="12" lg="2" md="3" sm="3">
                <Label className="text-black" for="depth">
                  Longueur (cm)
                  <span>*</span>
                </Label>
                <Input
                  className="custom-input"
                  id={`${item._id}`}
                  required
                  name="depth"
                  type="number"
                  value={item.depth > 0 ? item.depth : ""}
                  onChange={(t) => handleChangeInput(item._id, t)}
                />
              </Col>
              <Col xs="12" lg="2" md="3" sm="3">
                <Label className="text-black" for="weight">
                  Poids(Kg)
                  <span>*</span>
                </Label>
                <Input
                  className="custom-input"
                  id={`${item._id}`}
                  required
                  name="weight"
                  type="number"
                  value={item.weight > 0 ? item.weight : ""}
                  onChange={(t) => handleChangeInput(item._id, t)}
                />
              </Col>

              <Col xs="12" lg="1" md="3" sm="3">
                <Label className="text-black" for="quantity">
                  Quantité
                  <span>*</span>
                </Label>
                <Input
                  className="custom-input"
                  id={`${item._id}`}
                  required
                  name="quantity"
                  type="number"
                  value={item.quantity > 0 ? item.quantity : ""}
                  onChange={(t) => handleChangeInput(item._id, t)}
                />
              </Col>
              {reference.length > 1 ? (
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs="12"
                  lg="1"
                  md="3"
                  sm="3"
                >
                  <div
                    className="deleteRow text-medium"
                    onClick={() => handleRemoveFields(item._id)}
                  >
                    Supprimer
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <hr />
          </>
        ))}
        <Row>
          <Button
            className={` space-left `}
            onClick={(e) => {
              handleAddFields();
            }}
            size="lg"
            // disabled={invalidForm}
          >
            {fetching ? (
              <Spinner size="sm" />
            ) : (
              <span className="text-bold"> Ajouter </span>
            )}
          </Button>
        </Row>

        {error && <h6 className="auth-error">{error?.message}</h6>}
        <div className="creation-footer">
          <Button
            className={` space-left btnBack `}
            onClick={(e) => {
              // e.preventDefault();
              // onCreate();
              dispatch(push("/expeditions/createstep1"));
            }}
            size="lg"
            // disabled={invalidForm}
          >
            <span className="text-bold"> Précédent </span>
          </Button>
          <Button
            className={` space-left btnNext ${props.theme}`}
            onClick={(e) => {
              // e.preventDefault();
              onCreate();
              // dispatch(push("/expeditions/list"));
            }}
            size="lg"
            disabled={invalidForm[invalidForm.length - 1]}
          >
            {fetching ? (
              <Spinner size="sm" />
            ) : (
              <span className="text-bold"> Envoyer </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateExpeditions;
