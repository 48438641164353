import React from "react";
import { Route, Switch } from "react-router";

import routes from "./routes";

import "./styles.scss";

function Admins({ match, ...props }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={`${match.path}${route.path}`}
            render={(p) => <route.component {...p} />}
          />
        );
      })}
    </Switch>
  );
}

export default Admins;
