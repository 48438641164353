import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const listStateSelect = createSelector(
  (state) => state.user,
  (user) => user?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.user,
  (user) => user.details
);

export const createStateSelect = createSelector(
  (state) => state.user,
  (user) => user?.create
);
