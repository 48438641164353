import React, { useState, useEffect } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useCreateExpeditions } from "../../hooks/useCreateExpeditions";
import Switch from "../../../../components/Switch";
import { Flag, SettingsEthernet } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
import axios from "axios";
const CreateExpeditions = (props) => {
  const details = props.create.current;
  const [state, setState] = useState({
    $q: "",
    code: details?.code || "",
    email: details?.email || "",
    adresse: details?.adresse || "",
    city: details?.city || "",
    descriptif: details?.descriptif || "",
    destinataire: details?.destinataire || "",
    fullName: details?.fullName || "",
    mode: details?.mode
      ? details?.mode
      : { label: "National", value: "national" },
    origine: details?.origine || "",
    origineCompany: details?.origineCompany || "",
    origineAdresse: details?.origineAdresse || "",
    origineCity: details?.origineCity || "",
    originePays: details?.originePays || "",
    originePhone: details?.originePhone || "",
    origineZipcode: details?.origineZipcode || "",
    pays: details?.pays || "",
    phone: details?.phone || "",
    valeur: details?.valeur || "",
    zipcode: details?.zipcode || "",
    typeValeur: details?.typeValeur || { label: "MAD", value: "MAD" },
  });
  const [{ fetching, error }, createExpeditions] = useCreateExpeditions();
  const { role } = useSelector(currentUserStateSelect) || {};
  const [isCode, setIsCode] = useState(false);
  const invalidForm =
    // !isCode ||
    // !state.code ||
    !state.descriptif ||
    !state.fullName ||
    !state.adresse ||
    !state.city ||
    !state.pays ||
    !state.phone ||
    !state.zipcode ||
    !state.origine ||
    !state.origineAdresse ||
    !state.origineCity ||
    !state.originePays ||
    !state.originePhone ||
    !state.origineZipcode ||
    !state.valeur ||
    !state.typeValeur?.value;

  const onCreate = () => {
    props.actions.setCurrentExpeditions(state);
    dispatch(push("/expeditions/createstep2"));
  };

  const checkCode = (t) => {
    setState({ ...state, code: t });
  };

  useEffect(() => {
    setIsCode(/^130[0-9]{8}$/.test(state.code));
  }, [state.code]);

  console.log("isCode", isCode);
  console.log(props.create);
  const handleChange = (event) => {
    event.persist();
    const name = event.target.name;
    const is_checked = event.target.checked;
    setState({ ...state, name: is_checked });
  };
  const dispatch = useDispatch();

  // const getOrigineVille = () => {
  //   if (state.origineZipcode.length === 5) {
  //     axios
  //       .get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${state.origineZipcode}&language=fr&components=country:MA&key=AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg`
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         const city =
  //           res?.data?.results[0] &&
  //           res?.data?.results[0]?.address_components.find(
  //             (item) => item.types[0] === "locality"
  //           );
  //         setState({
  //           ...state,
  //           origineCity: city?.long_name ? city?.long_name : "",
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // };
  // useEffect(() => {
  //   getOrigineVille();
  // }, [state.origineZipcode]);

  // const getOrigineVille2 = () => {
  //   if (state.zipcode.length === 5) {
  //     axios
  //       .get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${state.zipcode}&language=fr&components=country:MA&key=AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg`
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         const city =
  //           res?.data?.results[0] &&
  //           res?.data?.results[0]?.address_components.find(
  //             (item) => item.types[0] === "locality"
  //           );
  //         setState({
  //           ...state,
  //           city: city?.long_name ? city?.long_name : "",
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // };
  // useEffect(() => {
  //   getOrigineVille2();
  // }, [state.zipcode]);

  console.log(state);
  return (
    <div className="app-main">
      <PageDescription title="Nouvelle expédition" />
      <span className="sousTitle text-bold">Coordonnées</span>
      <div className="creation-form">
        {/* <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Code"
              required
              type="number"
              value={state.code}
              onChange={(t) => checkCode(t)}
            />
            <div style={{ color: "red" }}>
              {!isCode && state.code
                ? "Format code  130XXXXXXXX (X chiffre entre 0 et 9)"
                : ""}
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col xs="12" sm="4">
            <CustomSimpleSelect
              label={"Mode"}
              options={[
                { label: "National", value: "National" },
                { label: "International", value: "International" },
              ]}
              defaultValue={[state.mode]}
              onSelectValue={(v) => {
                setState({ ...state, mode: v });
              }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Origine"
              required
              value={state.origine}
              onChange={(t) => setState({ ...state, origine: t })}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Origine (société) (facultatif)"
              value={state.origineCompany}
              onChange={(t) => setState({ ...state, origineCompany: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Adresse"
              required
              value={state.origineAdresse}
              onChange={(t) => setState({ ...state, origineAdresse: t })}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Pays"
              required
              value={state.originePays}
              onChange={(t) => setState({ ...state, originePays: t })}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Code postal"
              required
              type="number"
              maxlength="5"
              value={state.origineZipcode}
              onChange={(t) => {
                if (!isNaN(Number.parseInt(t)) && t.length <= 5) {
                  setState({ ...state, origineZipcode: t });
                } else if (t.length > 5) {
                  setState({ ...state, origineZipcode: state.origineZipcode });
                } else {
                  setState({ ...state, origineZipcode: "" });
                }
              }}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Ville"
              required
              value={state.origineCity}
              onChange={(t) => setState({ ...state, origineCity: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Téléphone"
              type="number"
              required
              value={state.originePhone}
              onChange={(t) => setState({ ...state, originePhone: t })}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Destinataire (Nom prénom)"
              required
              value={state.fullName}
              onChange={(t) => setState({ ...state, fullName: t })}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Destinataire (société) (facultatif)"
              value={state.destinataire}
              onChange={(t) => setState({ ...state, destinataire: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Adresse"
              required
              value={state.adresse}
              onChange={(t) => setState({ ...state, adresse: t })}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Pays"
              required
              value={state.pays}
              onChange={(t) => setState({ ...state, pays: t })}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Code postal"
              required
              type="number"
              value={state.zipcode}
              onChange={(t) => {
                if (!isNaN(Number.parseInt(t)) && t.length <= 5) {
                  setState({ ...state, zipcode: t });
                } else if (t.length > 5) {
                  setState({ ...state, zipcode: state.zipcode });
                } else {
                  setState({ ...state, zipcode: "" });
                }
              }}
            />
          </Col>
          <Col xs="12" sm="2">
            <AppInput
              label="Ville"
              required
              value={state.city}
              onChange={(t) => setState({ ...state, city: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Téléphone"
              type="number"
              required
              value={state.phone}
              onChange={(t) => setState({ ...state, phone: t })}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Email"
              type="email"
              value={state.email}
              onChange={(t) => setState({ ...state, email: t })}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Valeur"
              required
              type="number"
              value={state.valeur}
              onChange={(t) => setState({ ...state, valeur: t })}
            />
          </Col>
          <Col className="mt-2" xs="12" sm="2">
            <CustomSimpleSelect
              label={" "}
              options={[
                { label: "MAD", value: "MAD" },
                { label: "$", value: "USD" },
                { label: "€", value: "EUR" },
              ]}
              defaultValue={[state.typeValeur]}
              onSelectValue={(v) => {
                setState({ ...state, typeValeur: v });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="8">
            <AppInput
              required
              label="Descriptif"
              type="textarea"
              value={state.descriptif}
              onChange={(t) => setState({ ...state, descriptif: t })}
              autoComplete="off"
            />
          </Col>
        </Row>
      </div>
      {error && <h6 className="auth-error">{error?.message}</h6>}
      <div className="creation-footer">
        <Button
          className={` space-left btnNext ${props.theme}`}
          onClick={(e) => {
            e.preventDefault();
            onCreate();
          }}
          size="lg"
          disabled={invalidForm}
        >
          {fetching ? (
            <Spinner size="sm" />
          ) : (
            <span className="text-bold"> Suivant </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CreateExpeditions;
