import React, { useState } from "react";

import { Col, Row, Button, Spinner } from "reactstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";

import ErrorLabel, { usePasswordCheck } from "hooks/usePasswordCheck";

import TopNavbar from "components/Navbars/TopNavbar";
import AppInput from "components/AppInput";

import { Api } from "api";

import "./passreset-styles.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MonEspace(props) {
  const [password, setPassword] = useState("");
  const [passwordActuel, setPasswordActuel] = useState("");
  const [confirm, setConfirm] = useState("");
  const [openPassword, setOpenPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPasswordActuel, setOpenPasswordActuel] = useState(false);
  const [confirmHasError, setConfirmError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [displayAlert, setDisplayAlert] = useState({
    display: false,
    text: "",
    error: false,
  });
  const { isValid: passwordValid } = usePasswordCheck(password);
  console.log("currentUser", props.currentUser?.roles[0]);
  const onSubmit = async () => {
    try {
      if (password !== confirm) {
        setConfirmError(true);
      } else {
        setFetching(true);
        setConfirmError(false);
        // send to BE
        await Api().post(
          `${
            props.currentUser?.roles[0] === "user"
              ? "/auth/users/password"
              : "/auth/admins/password"
          }`,
          {
            currentPassword: passwordActuel,
            newPassword: password,
            verifyPassword: confirm,
          }
        );
        setPassword("");
        setConfirm("");
        setDisplayAlert({
          display: true,
          text: "Votre mot de passe a bien été modifié",
          error: false,
        });
        props.history.replace("/auth");
      }
    } catch (e) {
      console.log("change pass error", e);
      setDisplayAlert({
        display: true,
        text: e?.message || "Erreur de modification",
        error: true,
      });
    } finally {
      setFetching(false);
    }
  };

  return (
    <div className="app-container">
      <div className="app-content">
        <div className="space-page-container">
          <h3 className="space-page-title-text">Modifier le mot de passe</h3>

          <div className="space-step-form">
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label=" Mot de passe actuel "
                  value={passwordActuel}
                  onChange={(t) => setPasswordActuel(t)}
                  type={openPasswordActuel ? "text" : "password"}
                  leftAddOn={
                    <div
                      onClick={() => setOpenPasswordActuel(!openPasswordActuel)}
                      className="input-addon"
                    >
                      <img
                        alt="+"
                        src={
                          openPasswordActuel
                            ? require("assets/img/eye-b.png")
                            : require("assets/img/eye.png")
                        }
                      />
                    </div>
                  }
                />
                {passwordActuel !== "" && (
                  <ErrorLabel password={passwordActuel} />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Nouveau mot de passe"
                  value={password}
                  onChange={(t) => setPassword(t)}
                  type={openPassword ? "text" : "password"}
                  leftAddOn={
                    <div
                      onClick={() => setOpenPassword(!openPassword)}
                      className="input-addon"
                    >
                      <img
                        alt="+"
                        src={
                          openPassword
                            ? require("assets/img/eye-b.png")
                            : require("assets/img/eye.png")
                        }
                      />
                    </div>
                  }
                />
                {password !== "" && <ErrorLabel password={password} />}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Confirmer mot de passe"
                  value={confirm}
                  onChange={(t) => setConfirm(t)}
                  type={openConfirm ? "text" : "password"}
                  leftAddOn={
                    <div
                      onClick={() => setOpenConfirm(!openConfirm)}
                      className="input-addon"
                    >
                      <img
                        alt="+"
                        src={
                          openConfirm
                            ? require("assets/img/eye-b.png")
                            : require("assets/img/eye.png")
                        }
                      />
                    </div>
                  }
                />
                {confirm !== "" && <ErrorLabel password={confirm} />}
                {confirmHasError && (
                  <h6 className="login-input-error">
                    Les mots de passes ne sont pas identiques
                  </h6>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <Button
                  className="btn-round btnNext"
                  color="secondary"
                  onClick={(e) => {
                    // e.preventDefault();
                    onSubmit();
                  }}
                  size="lg"
                  disabled={!passwordValid || !confirm}
                >
                  {fetching ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    <span className="text-bold">Sauvegarder</span>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Snackbar
        open={displayAlert.display}
        onClose={() =>
          setDisplayAlert({ display: false, text: "", error: false })
        }
        TransitionComponent={Fade}
        key={Fade.name}
      >
        <Alert
          onClose={() =>
            setDisplayAlert({ display: false, text: "", error: false })
          }
          severity={displayAlert.error ? "error" : "info"}
        >
          {displayAlert.text}
        </Alert>
      </Snackbar>
    </div>
  );
}
