import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const procedureStateSelect = createSelector(
  (state) => state.app,
  (app) => app.procedureId
);

export const userDataStateSelect = createSelector(
  (state) => state.declaration,
  (declaration) => declaration.user
);

export const fichiersStateSelect = createSelector(
  (state) => state.declaration,
  (declaration) => declaration.fichiers
);
