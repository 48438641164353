import Settings from "modules/Settings";
import Expeditions from "modules/Expeditions";
import PasswordReset from "modules/PasswordReset";
import Dashboard from "modules/Dashboard";
import Utilisateur from "modules/Utilisateurs";

const routes = [
  { path: "/settings", component: Settings },
  { path: "/expeditions", component: Expeditions },
  { path: "/passwordreset", component: PasswordReset },
  { path: "/utilisateurs", component: Utilisateur },
  { path: "/tableaubord", component: Dashboard },
];

export default routes;
