import React from "react";
import "../../Expeditions.styles.scss";
import { useSelector } from "react-redux";
import { currentUserStateSelect } from "../../selector";
import moment from "moment";

// const { roles } = useSelector(currentUserStateSelect) || {};
export const columns = [
  {
    title: "Date de création",
    dataIndex: "created_at",
    renderItem: (info, props, cb) => (
      <span onClick={cb} className="cell-container">
        {info.created_at
          ? `${moment(info.created_at).format("DD/MM/YYYY ")}`
          : ""}
      </span>
    ),
  },
  {
    title: "AWB Ref",
    dataIndex: "code",
    renderItem: (info, props) => (
      <span className="text-center">{info.code}</span>
    ),
  },
  {
    title: "Sender",
    dataIndex: "sender",
    renderItem: (info, props) => (
      <span className="text-center">{info?.sender?.name}</span>
    ),
  },
  {
    title: "Consigne (Full Name)",
    dataIndex: "receiver",
    renderItem: (info, props) => (
      <span className="text-center">{info?.receiver?.name}</span>
    ),
  },
  {
    title: "Address",
    dataIndex: "receiver",
    renderItem: (info, props) => (
      <span className="text-center">{info?.receiver?.address1}</span>
    ),
  },
  {
    title: "Ville",
    dataIndex: "receiver",
    renderItem: (info, props) => (
      <span className="text-center">{info?.receiver?.city}</span>
    ),
  },
  {
    title: "Phone Details",
    dataIndex: "receiver",
    renderItem: (info, props) => (
      <span className="text-center">{info?.receiver?.phone}</span>
    ),
  },
  {
    title: "Email",
    dataIndex: "receiver",
    renderItem: (info, props) => (
      <span className="text-center">{info?.receiver?.email}</span>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "total_quantity",
    renderItem: (info, props) => (
      <span className="text-center">{info.total_quantity}</span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    renderItem: (info, props) => (
      <span
        className="text-center"
        style={{
          backgroundColor: "#0095c9",
          color: "white",
          padding: "10px",
          width: "100px",
          borderRadius: "35px",
        }}
      >
        {info.status === "new"
          ? "Nouvelle"
          : info.status === "shipped"
          ? "Livrée"
          : info.status === "loaded"
          ? "En cours de livraison"
          : info.status === "processing"
          ? "En cours de traitement"
          : info.status === "excepted"
          ? "Exception"
          : "Sans Status"}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "none",
    renderItem: () => {
      return (
        <div className="text-right">
          <img
            src={require("assets/img/chevron-right.png")}
            className="chevron-right"
            alt=">"
          />
        </div>
      );
    },
  },
];
