import React, { useState, useContext, useEffect, useMemo } from "react";

import { ReactReduxContext } from "react-redux";

export const themes = {
  primary: "red",
  secondary: "secondary",
  violet: "violet",
};

export const ThemeContext = React.createContext(
  themes.violet // default value
);

export const ThemeProvider = (props) => {
  const reduxContext = useContext(ReactReduxContext);

  const app = useMemo(() => {
    return reduxContext.store?.getState().app;
  }, [reduxContext.store]);

  const color = useMemo(() => {
    return app.color;
  }, [app.color]);

  const [theme, setTheme] = useState(themes[color]);

  useEffect(() => {
    setTheme(themes[color]);
  }, [color]);

  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  );
};
