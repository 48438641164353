import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*
 * Import reducers
 */
import app from "./appReducer";
import auth from "modules/Auth/reducers";
import expeditions from "modules/Expeditions/reducers";
import user from "modules/Utilisateurs/reducers";

const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    expeditions,
    user,
  });

export default createReducers;
