import React, { useState, useEffect } from "react";

import { Input, Button } from "reactstrap";
import Select from "react-select";

import PageDescription from "components/PageDescription";
import CustomTable from "components/CustomTable";
import EmptyList from "components/EmptyList";
import { FormGroup, Label } from "reactstrap";
import useFetchList from "hooks/useFetchList";

import { expeditionsUrl } from "../../api/endpoints";
import * as tableConfig from "./tableConfig";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import "assets/css/listviews.css";
import RangePicker from "react-range-picker";
import "../../Expeditions.styles.scss";
import downloadApi from "api/downloadApi";
import { downloadFile } from "lib/helpers";
import { Api } from "api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
const ListExpeditions = (props) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      backgroundColor: "#f8f8f8",
      borderRadius: "2px",
      height: "50px",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f8f8f8",
      borderRadius: "2px",
      borderWidth: "0px",
      height: "50px",
      width: "100%",
      color: "#373737",
      fontSize: "1.05em",
      fontWeight: "bold",
    }),
  };

  const { userRole, list } = props;
  const [fetchParams, setFecthParams] = useState({
    skip: list?.search?.skip,
    top: list?.search?.top,
    q: list?.search?.q,
    Status: list?.search?.Status,
    $q: "",
  });
  const [apiEndpoint, setEndpoint] = useState(expeditionsUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);
  // const [dateRange, setDateRange] = useState([]);

  console.log("search", list);

  const [dateRange, setDateRange] = useState([
    list?.search?.created_at_from
      ? moment(list?.search?.created_at_from).toDate()
      : null,
    list?.search?.created_at_to
      ? moment(list?.search?.created_at_to).toDate()
      : null,
  ]);
  const [created_at_from, created_at_to] = dateRange;
  useEffect(() => {
    const setSearch = () => {
      props.actions.setSearchExpeditions({
        skip: fetchParams.skip,
        top: fetchParams.top,
        Status: fetchParams.Status,
        created_at_from: created_at_from,
        created_at_to: created_at_to,
        q: fetchParams.q,
      });
    };
    setSearch();
  }, [
    fetchParams.skip,
    fetchParams.top,
    fetchParams.Status,
    fetchParams.q,
    created_at_from,
    created_at_to,
  ]);

  useEffect(() => {
    const Query = {
      status: fetchParams.Status?.value,
    };

    const urlQuery = created_at_from
      ? `${`&created_at_from=${moment(created_at_from).format(
          "DD/MM/YYYY"
        )}&created_at_to=${moment(created_at_to).format(
          "DD/MM/YYYY"
        )}`}&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }${"&$filter=" + JSON.stringify(Query)}`
      : `&$skip=${fetchParams.skip}&$top=${fetchParams.top}${
          fetchParams.q ? "&$q=" + fetchParams.q : ""
        }${"&$filter=" + JSON.stringify(Query)}`;
    setEndpoint(expeditionsUrl(userRole, urlQuery));
  }, [
    fetchParams.role,
    fetchParams.q,
    fetchParams.skip,
    fetchParams.top,
    userRole,
    created_at_from,
    created_at_to,
    dateRange,
    fetchParams.Status,
  ]);
  const exportCsv = async () => {
    try {
      // data = await Api().get(`/admin/courses/csv`);
      const url = await downloadApi("/admin/courses/csv");
      downloadFile(url, "Expeditions.csv");
      console.log("data", url);
    } catch (error) {
      console.log("error", error);
      // setError(error);
    }
  };

  console.log("fetchParams", fetchParams);

  const listStatut = [
    { label: "Nouvelle", value: "new" },
    { label: "En cours de traitement", value: "processing" },
    {
      label: "En Cours de livraison",
      value: "loaded",
    },
    { label: "Livrée", value: "shipped" },
    { label: "Exception", value: "excepted" },
  ];

  return (
    <div className="app-main list-Utilisateurs">
      <PageDescription title="Delivery Manifest" />
      <div className="filters-view">
        <div>
          <span
            className="text-bold export-btn"
            tag="button"
            onClick={exportCsv}
          >
            Exporter en CSV
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginBottom: "24px",
              marginRight: "15px",
              // width: "280px",
              // position: "relative",
            }}
          >
            <Label>Date</Label>
            <br />
            <DatePicker
              placeholderText="Choisir date"
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              startDate={created_at_from}
              endDate={created_at_to}
              onChange={(update) => {
                setDateRange(update);
                setFecthParams({ ...fetchParams, skip: 0, top: 10 });
              }}
              isClearable={true}
              locale={fr}
            />
          </div>
          <FormGroup>
            <Label className="text-black">Status</Label>

            <Select
              styles={customStyles}
              className="filter-input ml-4"
              name="colors"
              options={listStatut}
              placeholder="Choisir"
              isClearable
              className="basic-multi-select"
              value={fetchParams.Status}
              onChange={(c) => {
                setFecthParams({ ...fetchParams, Status: c, skip: 0, top: 10 });
              }}
            />
          </FormGroup>

          <FormGroup className="ml-2 ">
            <Label className="text-black">Ville</Label>
            <Input
              width="200px"
              placeholder="Tous"
              type="text"
              name="filter-name"
              className={"filter-input"}
              value={fetchParams.q}
              onChange={(e) => {
                e.preventDefault();
                setFecthParams({
                  ...fetchParams,
                  q: e.currentTarget.value,
                  skip: 0,
                  top: fetchParams.top,
                });
              }}
            ></Input>
          </FormGroup>
        </div>
      </div>

      <div>
        {data.length === 0 ? (
          <EmptyList fetching={fetching} text={"Aucune donnée"} />
        ) : (
          <CustomTable
            columns={tableConfig.columns}
            dataSource={data}
            cellKey={"id"}
            onRowPress={(item) => {
              props.actions.setDetailsExpeditions(item);
              props.history.push("/Expeditions/details");
            }}
            rowClickable
            pagination={{
              count,
              rowsPerPage: fetchParams.top,
              page: Math.round(fetchParams.skip / fetchParams.top),
            }}
            handleChangePage={(params) => {
              setFecthParams({ ...fetchParams, ...params });
            }}
            handleChangeRowsPerPage={(params) => {
              setFecthParams({ ...fetchParams, ...params });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListExpeditions;
