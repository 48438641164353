// routes components
import App from "modules/App";
import Auth from "modules/Auth";
import SearchExpedition from "modules/SearchExp";
// import Landing from 'modules/Landing';

const routes = [
  // {
  //   path: '/landing',
  //   component: Landing,
  //   exact: true,
  // },
  {
    path: "/auth",
    component: Auth,
  },
  { path: "/suivi-expedition", component: SearchExpedition },
  {
    component: App,
  },
];

export default routes;
