import React, { useContext } from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import apiConfig from "config/apiConfig";
import { ThemeContext } from "contextProviders/ThemeProvider";

import SubMenu from "./SubMenu";

import { currentUserSelect } from "../selector";

import "assets/css/sidebar.css";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../Expeditions/actions";
const { BASE_URL } = apiConfig;

const SideBar = ({ isOpen, toggle, activeTab, setTab, displayLogout }) => {
  const theme = useContext(ThemeContext);
  const { logo_url, roles } = useSelector(currentUserSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  console.log("role", roles);
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={() => toggle()} style={{ color: "#000" }}>
          &times;
        </span>
        <div className="text-center">
          <img
            style={{
              display: "flex",
              margin: "0 auto",
              width: "225px",
              padding: "10px",
              height: "100%",
              marginTop: "5px",
            }}
            className="imgFood"
            alt=".."
            src={require("assets/img/logo.png")}
          />
        </div>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          <hr />
          <NavItem
            onClick={() => {
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("tableaubord");
            }}
            className={classNames({
              [`app-text-Active`]: activeTab === "tableaubord",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-Active`]: activeTab === "tableaubord",
              })}
              tag={Link}
              to={"/tableaubord"}
            >
              <span className="side-item-title">+ Tableau bord</span>
            </NavLink>
          </NavItem>
          <hr />
          <NavItem
            onClick={() => {
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("Expeditions");
            }}
            className={classNames({
              [`app-text-Active`]: activeTab === "Expeditions",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-Active`]: activeTab === "Expeditions",
              })}
              tag={Link}
              to={"/expeditions/list"}
            >
              <span className="side-item-title"> + Expéditions</span>
            </NavLink>
          </NavItem>
          <hr />
          <NavItem
            onClick={() => {
              actionsDispatch.setCurrentExpeditions([]);
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("ExpeditionsCreat");
            }}
            className={classNames({
              [`app-text-Active`]: activeTab === "ExpeditionsCreat",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-Active`]: activeTab === "ExpeditionsCreat",
              })}
              tag={Link}
              to={"/expeditions/createstep1"}
            >
              <span className="side-item-title"> + Créer une Expédition</span>
            </NavLink>
          </NavItem>
          <hr />
          <NavItem
            onClick={() => {
              if (window.innerWidth <= 800) {
                toggle();
              }
              setTab("ExpeditionsCreatPlus");
            }}
            className={classNames({
              [`app-text-Active`]: activeTab === "ExpeditionsCreatPlus",
            })}
          >
            <NavLink
              className={classNames({
                [`app-text-Active`]: activeTab === "ExpeditionsCreatPlus",
              })}
              tag={Link}
              to={"/expeditions/createplusieur"}
            >
              <span className="side-item-title">
                + Créer plusieurs Expéditions
              </span>
            </NavLink>
          </NavItem>
          <hr />
          {roles[0] === "admin" && (
            <>
              <NavItem
                onClick={() => {
                  if (window.innerWidth <= 800) {
                    toggle();
                  }
                  setTab("utilisateurs");
                }}
                className={classNames({
                  [`app-text-Active`]: activeTab === "utilisateurs",
                })}
              >
                <NavLink
                  className={classNames({
                    [`app-text-Active`]: activeTab === "utilisateurs",
                  })}
                  tag={Link}
                  to={"/utilisateurs/list"}
                >
                  <span className="side-item-title">+ Utilisateurs</span>
                </NavLink>
              </NavItem>
              <hr />
            </>
          )}

          {/* 
          <>
            <SubMenu
              theme={theme}
              icon={
                <img
                  className="navitem-logo"
                  alt="..."
                  src={require("assets/img/iconRoom.png")}
                />
              }
              title="Utilisateurs"
              menuTab="utilisateurs"
              activeTab={activeTab}
              items={
                role === "sysadmin"
                  ? [
                      {
                        target: "/utilisateurs/admins/list",
                        title: "+ Admins",
                        tab: "admins",
                      },
                      {
                        target: "/utilisateurs/managers/list",
                        title: "+ Managers",
                        tab: "managers",
                      },
                    ]
                  : [
                      {
                        target: "/utilisateurs/managers/list",
                        title: "+ Managers",
                        tab: "managers",
                      },
                    ]
              }
              onClickTab={(t) => {
                if (window.innerWidth <= 800) {
                  toggle();
                }
                setTab(t);
              }}
            />
            <hr />
          </>{" "}
          */}
        </Nav>
      </div>
      {/* <div
        tag="button"
        onClick={(e) => {
          displayLogout();
        }}
        className="sidebar-footer"
      >
        <div className="text-disconnect">Se déconnecter</div>
      </div> */}
    </div>
  );
};

export default SideBar;
