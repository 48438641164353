import React from "react";
import classNames from "classnames";

const Footer = ({ isSidebarOpen }) => {
  return (
    <footer className={classNames("footer app", { "is-open": isSidebarOpen })}>
      <div>{`© ${new Date().getFullYear()} TMM`}</div>
    </footer>
  );
};

export default Footer;
