export function loginUrl() {
  return "/auth/users/signin";
}

export function signupUrl() {
  return "/auth/admins/signup";
}

export function forgotUrl() {
  return "/auth/admins/forgot";
}
