import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";
import { v4 as uuidv4 } from "uuid";
// endpoints
import { createExpeditionsUrl } from "../api/endpoints";
import { createStateSelect } from "../selector";
export function useCreateExpeditions() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const details = useSelector(createStateSelect);
  async function createExpeditions(role, load) {
    try {
      // mode: details.mode.lable,
      setFetching(true);

      const payload = {
        mode: details.current?.mode?.label,
        // code: details.current?.code,
        sender: {
          name: details.current?.origine,
          company: details.current?.origineCompany,
          address1: details.current?.origineAdresse,
          address2: "",
          city: details.current?.origineCity,
          country: details.current?.originePays,
          zipcode: details.current?.origineZipcode,
          phone: details.current?.originePhone,
        },
        receiver: {
          name: details.current?.fullName,
          company: details.current?.destinataire,
          address1: details.current?.adresse,
          address2: "",
          city: details.current?.city,
          country: details.current?.pays,
          zipcode: details.current?.zipcode,
          phone: details.current?.phone,
          // email: details.current.email,
        },
        value: Number.parseFloat(details.current?.valeur),
        currency: details.current?.typeValeur?.value,
        description: details.current?.descriptif,
        load: load.map((item) => ({
          type: item.type,
          width: item.width,
          height: item.height,
          weight: item.weight,
          depth: item.depth,
          quantity: item.quantity,
        })),
      };

      console.log("payload", payload);
      const rep = await Api().post(createExpeditionsUrl(role), payload);
      dispatch(push("/expeditions/list"));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetching(false);
    } finally {
      // setFetching(false);
    }
  }

  return [{ fetching, error }, createExpeditions];
}
