import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "contextProviders/ThemeProvider";
import * as actions from "../../actions";
import { detailsStateSelect, currentUserStateSelect } from "../../selector";
import DetailsExpeditions from "./component";

const DetailsExpeditionsContainer = (props) => {
  const theme = useContext(ThemeContext);
  const details = useSelector(detailsStateSelect);
  const { roles } = useSelector(currentUserStateSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);

  console.log("details", details);
  console.log("roles?.[0]", roles?.[0]);
  return (
    <DetailsExpeditions
      userRole={roles?.[0]}
      theme={theme}
      details={details}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default DetailsExpeditionsContainer;
