import React, { useState, useMemo, useEffect } from "react";

import PageDescription from "components/PageDescription";
import useFetchElement from "hooks/useFetchElement";
import { searchByIdUrl } from "../Expeditions/api/endpoints";
import {
  Row,
  Col,
  Label,
  Button,
  Spinner,
  FormGroup,
  Input,
  Container,
} from "reactstrap";
import { Api } from "api";
import moment from "moment";

const DetailsExpeditions = (props) => {
  const [Expeditions, setExpeditions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [state, setState] = useState({});
  const [error, setError] = useState({});

  console.log(state);

  console.log("Expeditions", Expeditions, Expeditions.length > 0);

  const onSubmit = async () => {
    try {
      setFetching(true);
      const data = await Api().get(searchByIdUrl(state.code));
      setFetching(false);
      setExpeditions(data);
      setError("");
    } catch (error) {
      setFetching(false);
      setError(error);
      setExpeditions([]);
      // maybe display an error message
    }
  };
  console.log("error", error.message);
  return (
    <div className="app-main details">
      <div className="barSearch">
        <div className="logo">
          <img src={require("assets/img/logo.png")} alt=">" />
        </div>
        <div className="containerSearch">
          <Input
            className="search-input"
            placeholder=""
            type="text"
            value={state.code}
            onChange={(e) => {
              setState({ code: e.currentTarget.value });
            }}
            autoComplete="off"
          />
          <Button
            className="btn-round btnNext"
            color="secondary"
            onClick={(e) => {
              onSubmit();
            }}
            size="lg"
            disabled={!state.code}
          >
            {fetching ? (
              <Spinner size="sm" color="light" />
            ) : (
              <span className="text-bold">Suivre</span>
            )}
          </Button>
        </div>
        {error && <div style={{ color: "red" }}>{error.message}</div>}
      </div>

      {Expeditions?.code && (
        <Container className="mt-4">
          {/* <PageDescription title="Détails de l'Expéditions" /> */}
          <Row>
            <Col lg="6" sm="12">
              <span className="sousTitle text-bold">Coordonnées</span>
              <div className="creation-form">
                <Row className="mt-2">
                  <span className="text-bold"> Date de création :&nbsp; </span>
                  <span>
                    {" "}
                    {`${moment(Expeditions.created_at).format("DD/MM/YYYY ")}`}
                  </span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">AWB Ref :&nbsp;</span>
                  <span> {Expeditions?.code}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Mode :&nbsp; </span>
                  <span> {Expeditions?.mode}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Origine :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.name}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Origine (société) :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.company}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Adresse :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.name}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Pays :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.country}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Ville :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.city}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Code postal :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.zipcode}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Téléphone :&nbsp;</span>{" "}
                  <span> {Expeditions?.sender?.phone}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Destinataire :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.name}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">
                    Destinataire (société) :&nbsp;
                  </span>{" "}
                  <span> {Expeditions?.receiver?.company}</span>
                </Row>
                {Expeditions?.receiver?.email && (
                  <Row className="mt-2">
                    <span className="text-bold"> Email :&nbsp; </span>
                    <span> {Expeditions?.receiver?.email}</span>
                  </Row>
                )}
                <Row className="mt-2">
                  <span className="text-bold">Adresse :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.name}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Pays :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.country}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Ville :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.city}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Code postal :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.zipcode}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Téléphone :&nbsp;</span>{" "}
                  <span> {Expeditions?.receiver?.phone}</span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Valeur :&nbsp;</span>{" "}
                  <span>
                    {Expeditions?.value} {Expeditions.currency}
                  </span>
                </Row>
                <Row className="mt-2">
                  <span className="text-bold">Descriptif :&nbsp;</span>{" "}
                  <span> {Expeditions?.description}</span>
                </Row>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <span className="sousTitle text-bold">Conditionnement</span>
              <table className="mt-4 mb-4">
                <tr>
                  <th>Nature</th>
                  <th>Largeur (cm)</th>
                  <th>Hauteur (cm)</th>
                  <th>Longueur (cm)</th>
                  <th>Poids volumétrique (Kg)</th>
                  <th>Quantité</th>
                </tr>
                {Expeditions?.load.map((item) => (
                  <tr>
                    <td>{item.type}</td>
                    <td>{item.width}</td>
                    <td>{item.height}</td>
                    <td>{item.depth}</td>
                    <td>{item.volumetric_weight}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </table>
              <span className="sousTitle text-bold mt-4 mb-4">Historique</span>
              <hr />
              {Expeditions?.history &&
                Expeditions?.history.map((item) => (
                  <div key={item.id}>
                    <Row>
                      <Col xs="12" sm="6">
                        <span
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "16px",
                            color: "#fff",
                            letterSpacing: "-0.16px",
                            fontWeight: "700",
                          }}
                        >
                          {item.status === "new"
                            ? "Nouvelle"
                            : item.status === "loaded"
                            ? "En Cours de livraison"
                            : item.status === "shipped"
                            ? "Livrée"
                            : "Exception"}
                        </span>
                      </Col>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        xs="12"
                        sm="6"
                      >
                        <div>
                          <span
                            style={{
                              fontFamily: "Helvetica",
                              fontSize: "14px",
                              color: "#fff",
                              letterSpacing: "-0.14px",
                            }}
                          >
                            {`${moment(item.date).format("DD/MM/YYYY HH:mm")}`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-2 text-regular" xs="12" sm="6">
                        <span
                          style={{
                            fontStyle: "italic ",
                            fontSize: "16px",
                            color: "#444B54",
                            letterSpacing: "-0.16px",
                          }}
                          className="ml-2 mt-2 text-regular"
                        >
                          {item.comment ? `“${item.comment}”` : ""}
                        </span>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ))}
              {/* {listComment.length > 9 && (
            <>
              <hr />
              <Row>
                <Col xs="12" sm="12">
                  <span
                    onClick={() => setSkip(skip + 10)}
                    className="text-center mb-4"
                    style={{ cursor: "pointer" }}
                  >
                    Afficher plus
                  </span>
                </Col>
              </Row>
            </>
          )} */}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default DetailsExpeditions;
