export function userUrl(role, params) {
  return params && `/admin/users/?${params}&$sort={ "updated_at": "DESC"}`;
}

export function createUserUrl(role) {
  return `/admin/users/`;
}
export function userByIdUrl(role, id) {
  return `/admin/users/${id}`;
}
