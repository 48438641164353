import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Spinner,
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AppInput from "components/AppInput";

// some hooks
import { useSignIn } from "../../hooks/useSignIn";

import "./login-styles.scss";

function LoginView(props) {
  useEffect(() => {
    document.body.classList.add("login-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openPassword, setOpenPassword] = useState(false);

  const [{ fetching, error }, signin] = useSignIn();

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = { username: email, password: password };
    signin(payload);
  };

  return (
    <div className="app-container signin">
      <AuthNavbar signin />
      <div
        style={{ marginTop: "15vh", minHeight: "90vh" }}
        className="bg-image login-content"
      >
        <Col md="4">
          <h3 className="text-black text-bold auth-title">Connectez-vous</h3>
          <Form action="" onSubmit={onSubmit} className="form" method="">
            <Card className="card-login card-plain">
              <CardBody>
                <AppInput
                  label="Login"
                  value={email}
                  onChange={(t) => {
                    setEmail(t);
                  }}
                  id="login-email"
                  name="login-email"
                  type="email"
                />
                <AppInput
                  label="Mot de passe"
                  id="login-pwd"
                  name="pwd"
                  placeholder=""
                  type={openPassword ? "text" : "password"}
                  value={password}
                  onChange={(t) => setPassword(t)}
                  leftaddon={
                    <div
                      onClick={() => setOpenPassword(!openPassword)}
                      className="input-addon"
                    >
                      <img
                        alt="+"
                        src={
                          openPassword
                            ? require("assets/img/eye-b.png")
                            : require("assets/img/eye.png")
                        }
                      />
                    </div>
                  }
                />
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  block
                  className="btn-round on-secondary"
                  color="secondary"
                  size="lg"
                  type="submit"
                  disabled={!email || !password || fetching}
                >
                  {fetching ? (
                    <Spinner size="sm" />
                  ) : (
                    <span className="text-bold"> Se connecter </span>
                  )}
                </Button>
              </CardFooter>
              {error && (
                <h6 className="auth-error">
                  {error.message || "Erreur de connexion"}
                </h6>
              )}
              <div className="other-actions">
                {/* <div
                  tag={"button"}
                  onClick={() => {
                    props.history.push("/auth/signup");
                  }}
                  className="bottom-action"
                >
                  Je n'ai pas de compte
                </div> */}
                <div
                  onClick={() => {
                    props.history.push("/auth/forgot");
                  }}
                  className="bottom-action"
                >
                  J'ai oublié mon mot de passe
                </div>
              </div>
            </Card>
          </Form>
          {/* <div className="bottom-cgu"></div>
          <div className="bottom-cgu">
            <span className="text-black">
              En continuant, vous acceptez les{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                conditions générales d'utilisations{' '}
              </a>{' '}
              et notre{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                politique de confidentialité{' '}
              </a>{' '}
            </span>
          </div> */}
        </Col>
      </div>
    </div>
  );
}

export default LoginView;
